import "../styles/aw.scss"
import React, { useEffect } from "react"
import ActOne from "../components/AW/ActOne"
import Header from "../components/Header"
import ActTwo from "../components/AW/ActTwo"
import ActThree from "../components/AW/ActThree"
import ActFour from "../components/AW/ActFour"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"
// import favicon from "../images/favicon.ico";
import favicon from "../images/favicon.png"

export default function AW() {
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])
  return (
    <div className="aw">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apps & Websites</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <ActOne />
      <ActTwo />
      <ActThree />
      <ActFour />
      <Footer />
    </div>
  )
}
