import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import gsap from "gsap"
// import { useFirebaseArsenal } from "../lib/firebase/firebaseCentral"
import { useForm, useFormState } from "react-hook-form"
import toast from "react-hot-toast"
import firebase from "gatsby-plugin-firebase"
import CSSLoader from "./Loaders/CSSLoader"

const Footer = () => {
  const one = useRef(null)
  const two = useRef(null)
  const three = useRef(null)
  const four = useRef(null)

  // const { firebase } = useFirebaseArsenal()

  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    // formState: { errors, isValid },
    formState,
    getValues,
  } = useForm({
    mode: "onChange",
  })
  const { isValid } = useFormState({ control })

  const onSubmit = async data => {
    // console.log(getValues());
    setLoading(true)
    const email = getValues().email
    // console.log("email", email);

    const subscribeUserCallable = firebase
      .functions()
      .httpsCallable("subscribeUser")
    await subscribeUserCallable({
      // email: data.email,
      email,
    })
    reset({ email: "" })
    toast.success("You are subscribed!")
    setLoading(false)
    // setSent(true);
  }

  //   const handleBloom = () => {
  //     console.log("bloom")
  //     gsap.to(".disc", {
  //       duration: 1,
  //       scale: 0.1,
  //       y: 40,
  //       ease: "power1.inOut",
  //       stagger: {
  //         grid: [7, 15],
  //         from: "center",
  //         amount: 1.5,
  //       },
  //     })
  //   }
  return (
    <div className="footer">
      <div className="footer__top">
        <Link to="/hello">
          <div className="footer__discs">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 231">
              <g ref={one}>
                <circle
                  className="footer__disc footer__disc-one"
                  cx="115.5"
                  cy="115.5"
                  r="115"
                  id="fourth"
                />
              </g>
              <g ref={two}>
                <circle
                  className="footer__disc footer__disc-two"
                  cx="115.5"
                  cy="115.5"
                  r="100"
                  id="third"
                />
              </g>

              <g ref={three}>
                <circle
                  className="footer__disc footer__disc-three"
                  cx="115.5"
                  cy="115.5"
                  r="85"
                  id="second"
                />
              </g>
              <g ref={four}>
                <circle
                  className="footer__disc footer__disc-four"
                  cx="115.5"
                  cy="115.5"
                  r="70"
                  id="first"
                />
              </g>
              <g id="footer__text">
                <path
                  className="footer__text"
                  d="M571.94 415.86l1.45-.64a5 5 0 005.05 3.8c2.38 0 4.36-1.32 4.36-3.8s-1.71-3.35-4.16-4.33l-1.34-.53c-3-1.17-4.52-2.84-4.52-5.22 0-2.93 2.37-4.85 5.47-4.85a5.84 5.84 0 015.8 4.1l-1.36.67a4.5 4.5 0 00-4.39-3.16c-2.15 0-3.79 1.23-3.79 3.24 0 1.59 1 2.82 3.4 3.77l1.34.53c3.13 1.26 5.33 2.65 5.33 5.78 0 3.52-2.84 5.44-6.14 5.44a6.32 6.32 0 01-6.5-4.8zM586.71 413.13a7.11 7.11 0 017.2-7.54 6.29 6.29 0 015.8 3.38h.09v-3.1h1.7v14.51h-1.7v-3.1h-.09a6.27 6.27 0 01-5.8 3.38 7.1 7.1 0 01-7.2-7.53zm13.14 0a5.73 5.73 0 10-5.72 6 5.61 5.61 0 005.72-6zM617.72 405.87l-6.2 14.51-2.34 5.31h-1.68l2.4-5.42-6.14-14.4h1.79l5.19 12.34 5.19-12.34zM609.74 432v19.82H608v-8.9h-10.6v8.9h-1.73V432h1.73v9.3H608V432zM626.65 445.2h-12.47a5.66 5.66 0 005.94 5.41 6 6 0 004.61-1.95l.89.92a7.28 7.28 0 01-5.69 2.54 7.54 7.54 0 01-.2-15.07c3.68 0 7.4 2.68 6.92 8.15zm-12.47-1.37h10.94a5.21 5.21 0 00-5.39-5.28 5.48 5.48 0 00-5.55 5.28zM629.5 432h1.7v19.82h-1.7zM634.72 432h1.7v19.82h-1.7zM639.24 444.58a7.48 7.48 0 117.48 7.54 7.32 7.32 0 01-7.48-7.54zm13.26 0a5.78 5.78 0 10-5.78 6 5.69 5.69 0 005.78-6z"
                  transform="translate(-497.57 -310.7)"
                />
              </g>
            </svg>
          </div>
        </Link>
      </div>
      <div className="footer__content">
        <div className="footer__col">
          <div className="footer__subscribe">
            <div className="footer__subscribe-heading">Stay in the know.</div>
            {loading ? (
              <CSSLoader />
            ) : (
              <div className="footer__subscribe-email">
                <form className="subscribe-form">
                  <div className="subscribe-form-input-wrapper">
                    <div
                      // onSubmit={handleSubmit(onSubmit)}
                      onClick={() => onSubmit()}
                      className="subscribe-button"
                    >
                      <svg
                        className="subscribe-arrow"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 115 115"
                      >
                        <path d="M0 57.5a57.51 57.51 0 1016.84-40.65A57.47 57.47 0 000 57.5zm42.43 20.15a3.67 3.67 0 01-1.17.81 3.83 3.83 0 01-1.38.29 3.68 3.68 0 01-2.58-1.05 3.63 3.63 0 01-1-2.58 3.83 3.83 0 01.29-1.38 3.67 3.67 0 01.81-1.17l29.39-29.45h-19.9a3.6 3.6 0 110-7.19h28.57a3.59 3.59 0 013.6 3.6v28.59a3.6 3.6 0 11-7.19 0v-19.9z" />
                      </svg>
                    </div>
                    {/* <label htmlFor="email">Email</label> */}
                    <input
                      className="subscribe-input"
                      id="email"
                      type="email"
                      // placeholder="Enter your email"
                      {...register("email", {
                        required: { value: true, message: "email is required" },
                        minLength: { value: 5, message: "Min chars is 5" },
                        maxLength: {
                          value: 320,
                          message: "Max length is 320 characters",
                        },
                        pattern: {
                          value: /\S+@\S+.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                    />
                    {formState.errors?.email?.type === "required" && (
                      <div style={{ color: "#272727" }}>This is required</div>
                    )}
                    {formState.errors?.email?.type === "minLength" && (
                      <div style={{ color: "#272727" }}>
                        Minimum of 5 characters
                      </div>
                    )}
                    {formState.errors?.email?.type === "maxLength" && (
                      <div style={{ color: "#272727" }}>
                        Maximum of 320 characters
                      </div>
                    )}
                    {formState.errors?.email?.type === "pattern" && (
                      <div style={{ color: "#272727" }}>
                        Email pattern not recognized
                      </div>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <div className="footer__col">
          <div className="footer__ps-container">
            <div className="footer__ps-header">General</div>
            <Link className="footer__ps" to="/login">
              Login
            </Link>
            <Link className="footer__ps" to="/faq">
              FAQ
            </Link>
            <Link className="footer__ps" to="/hello">
              Contact Us
            </Link>
            <Link className="footer__ps" to="/privacy-policy">
              Privacy
            </Link>
          </div>
        </div>
        <div className="footer__col">
          <div className="footer__ps-container">
            <div className="footer__ps-header">Services</div>
            <Link className="footer__ps" to="/in-house">
              In-house SEO
            </Link>
            <Link className="footer__ps" to="/aw">
              Apps & Websites
            </Link>
            <div style={{ marginTop: "1.5rem" }} className="footer__ps-header">
              Products
            </div>
            <Link className="footer__ps" to="/berserkseo">
              berserkseo
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-year">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 147.71 46.82"
        >
          <path
            class="cls-1"
            d="M467.23 355.38c4.94-6.09 8.53-9.75 8.53-15.2s-3.79-8.79-9.24-8.79-9.94 3.27-9.94 9.94v1.09h-3.91V341c0-7.88 5.58-13.34 13.85-13.34s13.21 5.26 13.21 12.13c0 6.67-4.61 11.35-7.88 15.32l-12.51 15.2h20.59v3.53h-27.64ZM486.73 351.08c0-14 10.52-23.41 22.83-23.41s22.83 9.37 22.83 23.41-10.52 23.41-22.83 23.41-22.83-9.36-22.83-23.41Zm41.75 0c0-11.86-8.92-19.69-18.92-19.69s-18.92 7.83-18.92 19.69 8.92 19.69 18.92 19.69 18.92-7.77 18.92-19.69ZM553.5 355.38c4.94-6.09 8.53-9.75 8.53-15.2s-3.79-8.79-9.24-8.79-9.94 3.27-9.94 9.94v1.09h-3.91V341c0-7.88 5.58-13.34 13.85-13.34S566 332.93 566 339.8c0 6.67-4.61 11.35-7.88 15.32l-12.51 15.2h20.59v3.53h-27.64ZM587.3 355.38c4.94-6.09 8.53-9.75 8.53-15.2s-3.78-8.79-9.23-8.79-9.94 3.27-9.94 9.94v1.09h-3.92V341c0-7.88 5.58-13.34 13.86-13.34s13.21 5.26 13.21 12.13c0 6.67-4.62 11.35-7.89 15.32l-12.51 15.2H600v3.53h-27.64Z"
            transform="translate(-452.29 -327.67)"
          />
        </svg>
      </div>
    </div>
  )
}

export default Footer
