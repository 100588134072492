import "../../styles/components/css-loaders.scss"
import React from "react"
const CSSLoader = () => {
  return (
    <section style={{ position: "relative" }}>
      <div className="c-loader c-loader-1">
        <div className="loader-outer"></div>
        <div className="loader-inner"></div>
      </div>
    </section>
  )
}

export default CSSLoader
